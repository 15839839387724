import React, { useState, useEffect, useContext } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import About from './About';
import { TaqeStampsLinks } from './TaqeStamps';
import { CertificatesLinks } from './Certificates';
import { ProfessionalExperiencesLinks } from './ProfessionalExpiriences';
import { EducationLinks } from './Educations';
import { KnowledgesLinks } from './Knowledges';
import { LifeExperiencesLinks } from './LifeExpiriences';
import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar/index';
import { VacancyActivitiesLinks } from './VacancyActivities';
import { Candidate } from '../models/candidate';
import { User } from '../models/user';
import CandidateContext from '../contexts/Candidate';
import UserContext from '../contexts/UserContext'
import { getPublicCandidateData } from '../services/candidate';
import Loading from '../components/Loader';
import { PersonWithDisabilityLinks } from './PersonWithDisability';

export const PublicCandidatePage = () => {
  const { candidate, setCandidate } = useContext(CandidateContext);
  const { setUser } = useContext(UserContext);
  let [searchParams] = useSearchParams();
  const [profile, setProfile] = useState<Candidate>({} as Candidate);
  const [navbarHeight, setNavbarHeight] = useState<number | undefined>();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const { vacancyId, vacancyCandidateId } = useParams();

  const settingNavbarHeight = () => {
    const height = document.getElementById('mobile-nav')?.clientHeight;
    setNavbarHeight(height);
  };

  useEffect(() => {
    if (!candidate._id) {
      setShowLoading(true);
      getPublicCandidateData({
        vacancyId,
        vacancyCandidateId,
        token: searchParams.get('token'),
        sensitiveData: searchParams.get('sensitiveData'),
      })
        .then((response) => {
          const data = response.data;
          setProfile(data);
          setCandidate(data);
          setUser(data.user);
        })
        .catch((err) => {})
        .finally(() => setShowLoading(false));
    }

    if (candidate._id) {
      setProfile(candidate);
    }

    settingNavbarHeight();
  }, [navbarHeight, vacancyId, vacancyCandidateId, candidate, searchParams, setCandidate, setUser]);

  return showLoading ? (
    <Loading />
  ) : (
    <Container fluid className='g-0'>
      {profile && profile.user && (
        <Row className='g-0'>
          <Col
            lg={3}
            className='d-none d-lg-flex cover align-items-lg-start justify-content-lg-end'
          >
            <Sidebar
              basicInfo={profile.user.basicInformation}
              address={profile.user.address}
              photo={profile.user.photo}
              isCandidate={true}
            />
          </Col>
          <Col lg={9}>
            <div className='d-lg-none cover cover-sm'></div>
            <Row className='justify-content-center pt-lg-5'>
              <Col lg={10}>
                <About
                  basicInformation={profile.user.basicInformation}
                  aboutMe={profile.user.aboutMe}
                  address={profile.user.address}
                  photo={profile.user.photo}
                  presentationVideo={profile.user.presentationVideo}
                  documents={profile.user.identificationDocuments}
                  contact={profile.user.contact}
                  socialNetwork={profile.user.socialNetworks}
                  isExternalLink={false}
                />

                <TaqeStampsLinks candidate={profile.user} />

                {profile.user.certificates && !!profile.user.certificates.length &&
                  (
                    <CertificatesLinks
                      certificates={profile.user.certificates}
                    />
                  )
                }

                <EducationLinks
                  educations={profile.user.education}
                  levelEducation={profile.user.basicInformation.levelEducation}
                />

                <ProfessionalExperiencesLinks
                  experiences={profile.user.professionalExperience}
                />

                <KnowledgesLinks candidate={profile.user as User} />

                {
                  profile.user.disabilities &&  <PersonWithDisabilityLinks disabilities={profile.user.disabilities}/>
                }

                <LifeExperiencesLinks experiences={profile.user.volunteering} />

                <VacancyActivitiesLinks candidate={profile as Candidate} />

                <Footer paddingBottom={navbarHeight} />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </Container>
  );
};
