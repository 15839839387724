import API from "../helpers/Axios"

const getCandidateData = ({ vacancyId, vacancyCandidateId, token }: any) => {
    return API
        .get(
            `/private/vacancy-candidates/${vacancyId}/${vacancyCandidateId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            }
        )
}

const getPublicCandidateData = ({ vacancyId, vacancyCandidateId, token, sensitiveData }: any) => {
    return API.get(`/public/vacancy-candidates/${vacancyId}/${vacancyCandidateId}?sensitiveData=${sensitiveData}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    })
}

export {
    getCandidateData,
    getPublicCandidateData
}